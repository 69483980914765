<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'product/list'"
          :headers="companyHeaders"
          :title="title"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          icon="mdi-cart"
          tableType="product"
          tableUser="customer"
          v-on:add-product="dialogAddProduct=true"
        />
      </v-col>
    </v-row>
    <AddProduct
      :popupAddProduct="dialogAddProduct"
      v-on:closePopupAddProduct="dialogAddProduct=false"
      v-on:editTotalProducts="editTotalProducts"
    />
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
import AddProduct from "@/components/shared/popup/AddProduct";
export default {
  components: {
    Table,
    AddProduct
  },
  data: function() {
    return {
      dialogAddProduct: false,
      sortBy: ["issue_date"],
      sortDesc: [true]
    };
  },
  methods: {
    editTotalProducts() {
      //this.$parent.company.statistics.registered_products += 1;
    },
  },
  computed: {
    title() {
      return this.$vuetify.lang.t("$vuetify.cards.products")
    },
    companyHeaders() {
      return [
        {
          text: '',
          value: "selectable",
          selectable: true,
          sortable:false
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.barcode"),
          value: "barcode"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.barcode_type2"),
          value: "barcode_type"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tables.company"),
          value: "company.name",
          align: " d-none"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.brand_name"),
          value: "brand"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.name"),
          value: "name"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.type"),
          value: "type"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.volume"),
          value: "volume_amount"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.value"),
          value: "volume_unit",
          align: " d-none"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.item_package"),
          value: "package"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.product.barcode_issue_date"),
          value: "issue_date"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.action.action"),
          value: "viewProduct",
          sortable: false
        },
      ];
    }
  }
};
</script>